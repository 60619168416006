<template>
  <div class="contactCardContainer">
    <v-card>
      <v-container v-if="editing">
        <v-row>
          <v-col cols="12" sm="6" md="4" style="padding:0px 12px">
            <v-text-field label="First Name" v-model="newFirstName"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" style="padding:0px 12px">
            <v-text-field label="Last Name" v-model="newLastName"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" style="padding:0px 12px">
            <v-text-field label="Job Title" v-model="newTitle"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" style="padding:0px 12px">
            <v-text-field label="Email" v-model="newEmail"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" style="padding:0px 12px">
            <v-combobox
              v-model="newContactType" :items="contactTypeOptions" label="Contact Type"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="6" md="4" style="padding:0px 12px">
            <v-text-field label="Fax" v-model="newFax"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" style="padding:0px 12px">
            <v-text-field label="Direct Phone" v-model="newDirectPhone"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" style="padding:0px 12px">
            <v-text-field label="Mobile Phone" v-model="newMobilePhone"></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-card-title v-if="!editing">{{fullName}}</v-card-title>

      <v-card-text v-if="!editing">
        <b>{{title}}</b> | {{contactType}}
        <br>
        {{email}}
        <br><br>
        Direct Phone: {{directPhone}}
        <br>
        Mobile Phone: {{mobilePhone}}
        <br>
        Fax: {{fax}}
      </v-card-text>

      <v-divider class="mx-4"></v-divider>

      <v-card-actions>
        <!-- <v-btn color="#283c86" text @click='deleteContact'>Delete</v-btn> -->
        <v-btn v-if="!editing" color="#283c86" text @click='editing = true'>Edit</v-btn>
        <v-btn v-if="editing" color="#283c86" text @click='editing = false'>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="editing" color="#283c86" text @click='editContact'>Save</v-btn>
      </v-card-actions>

    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ContactCard',
  props: {
    fullName: String,
    title: String,
    email: String,
    directPhone: String,
    mobilePhone: String,
    fax: String,
    contactType: String,
    contactId: Number,
  },
  data() {
    return {
      contactTypeOptions: [
        '24 Hour Emergency Phone',
        'Billing (AR/AP)',
        'Management',
        'Operations',
        'Owner',
        'Sales',
      ],
      editing: false,
      newFirstName: this.fullName.split(' ')[0] || '',
      newLastName: this.fullName.split(' ')[1] || '',
      newTitle: this.title || '',
      newEmail: this.email || '',
      newDirectPhone: this.directPhone || '',
      newMobilePhone: this.mobilePhone || '',
      newFax: this.fax || '',
      newContactType: this.contactType || '',
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    deleteContact() {
      fetch(`${process.env.VUE_APP_API_URL}/api/delete-contact`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.token}` },
        body: JSON.stringify({ contactId: this.contactId }),
      })
        .then((response) => {
          if (response.status === 400) {
            this.$store.commit('notify', 'Failed to delete contact.');
          } else {
            console.log(response);
            this.$store.dispatch('getContacts');
          }
        }).catch((err) => console.log(err));
    },
    async editContact() {
      try {
        const url = `${process.env.VUE_APP_API_URL}/api/edit-contact`;
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${this.token}` };
        const body = JSON.stringify({
          contactId: this.contactId,
          firstName: this.newFirstName,
          lastName: this.newLastName,
          jobTitle: this.newTitle,
          email: this.newEmail,
          contactType: this.newContactType,
          fax: this.newFax,
          directPhone: this.newDirectPhone,
          mobilePhone: this.newMobilePhone,
        });
        const response = await fetch(url, { method: 'PATCH', headers, body });
        if (response.status !== 200) return this.$store.commit('notify', 'Failed to edit contact.');
        await this.$store.dispatch('getContacts');
        this.editing = false;
        return this.$store.commit('notify', 'New contact info saved.');
      } catch (err) {
        console.log(err);
        return this.$store.commit('notify', 'Failed to edit contact.');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .contactCardContainer {
    max-width: 440px; width: 100%; margin: 35px;
  }
</style>
